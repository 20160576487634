.button{
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 10px 22px;
    background-color : $primary;
    border:3px solid $primary;
    border-radius:10px;
    color:$text-color;
    font-size:19px;
    font-weight:bold;
    text-decoration:none;
    cursor:pointer;
    transition: all 0.25s;
    &:hover{
        background-color : $primary-dark;
        border-color: $primary-dark;
    }
    &--md {
        padding: 7px 20px;
        font-size: 18px;
    }
    &--sm{
        padding: 5px 16px;
        font-size: 16px;
    }
    &--xl {
        padding: 15px 35px;
        font-size: 25px;
    }
    &--black {
        background-color: $black;
        border-color: $black;
        color:$white;
        width: fit-content;
    }
    &--white{
        background-color: $white;
        border-color: $white;
    }
    &--white-outline {
        background-color: $white;
    }
    &--full{
        width:100%;
    }

    img, i{
        &:first-child{
            margin-right:12px;
        }
        &:last-child {
            margin-left: 12px;
        }
    }

}