.popin-contact{
    position:fixed;
    top:-150%;
    left:0;
    width:100%;
    height:100vh;
    z-index:12;
    background: linear-gradient(33.83deg, #000000 48.88%, #3E0A36 95.95%);
    color:$white;
    transition: all 0.25s;
    overflow-y: auto;
    &.active{
        top: 0;
        .popin-contact__close{
            display: block;
        }
    }
    .wrap{
        position: relative;
    }
    &__close{
        display:none;
        position: fixed;
        top: 20px;
        right: 20px;
        padding:0;
        background:0;
        color:$white;
        border:0;
        font-size: 40px;
        z-index:1;
        cursor:pointer;
        .material-icons{
            font-size:50px;
        }
    }
    &__content{
       display: flex;
       flex-direction: column;
       align-items: flex-end;
       justify-content: flex-end;
        padding:70px 0;
        @media (max-width: 1200px) {
            align-items: center;
        }
        @media (max-width: $mob) {
            display: block;
        }
        .content{
            &__img{
                position:absolute;
                left:0;
                bottom:0;
                @media (max-width: 1200px) {
                   display:none;
                }
            }
            &__title{
                width:100%;
                font-size:70px;
                font-weight:bold;
                text-align:center;
                @media (max-width: $tab) {
                    font-size: 55px;
                }
                @media (max-width: $mob) {
                    font-size: 35px;
                }
            }
            &__subtitle{
                width: 100%;
                margin-top:20px;;
                font-size: 28px;
                text-align: center;
                @media (max-width: $tab) {
                    font-size: 25px;
                }
                @media (max-width: $mob) {
                    font-size: 22px;
                }
                &::after{
                    content:'';
                    display: block;
                    width:125px;
                    height:5px;
                    margin:20px auto 50px;
                    background:$white;
                }
            }
            &__form {
                position:relative;
                display:flex;
                flex-wrap:wrap;
                justify-content: space-between;
                max-width:810px;
                .wpcf7-form{
                    position: relative;
                    display: flex;
                    flex-wrap: wrap;
                    justify-content: space-between;
                }
                .form{
                    &__line {
                        margin-bottom: 15px;
                        width: calc(50% - 15px);
                        @media (max-width: $mob) {
                            width: 100%;
                        }
                        &--full{
                            width:100%;
                        }
                        .line {
                            &__label {
                                display: block;
                                padding-left: 20px;
                                font-size: 20px;
                                font-family: $font-2;
                
                                @media (max-width: 1440px) {
                                    font-size: 18px;
                                }
                            }
                
                            &__input {
                                display: block;
                                width: 100%;
                                height: 45px;
                                box-sizing: border-box;
                                padding: 0 20px;
                                margin-top: -10px;
                                border: 0;
                                border-radius:10px;
                                font-family: $font-2;
                                font-size: 18px;
                
                                @media (max-width: 1440px) {
                                    height: 40px;
                                    font-size: 16px;
                                }
                            }
                            &__textarea{
                                display: block;
                                width: 100%;
                                height: 150px;
                                box-sizing: border-box;
                                padding: 10px 20px;
                                margin-top: -10px;
                                border: 0;
                                border-radius: 10px;
                                font-family: $font-2;
                                font-size: 18px;
                            
                                @media (max-width: 1440px) {
                                    font-size: 16px;
                                }
                            }
                
                        }
                
                        button,
                        input[type="submit"] {
                            -webkit-appearance: none;
                            font-family: $font-2;
                            margin-top: 10px;
                
                            @media (max-width: 1440px) {
                                margin-top: 10px;
                                padding: 8px 35px;
                                font-size: 20px;
                            }
                
                            @media (max-width: $mob) {
                                width: 100%;
                            }
                        }
                    }
                
                    &__help {
                        margin: 30px 0 0 20px;
                        font-style: italic;
                    }
                }
                
            }
            
            
        }
    }

}