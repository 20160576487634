.home-leagues{
    padding:100px 0 60px 0;
    background:$black;;
    .title{
        text-align:center;
    }
    &__list{
        display:flex;
        flex-wrap: wrap;
        justify-content: center;
        margin: 50px -11px 0;
        .list__item{
            position:relative;
            width:calc( (100% / 3) - 22px);
            max-width:400px;
            height: 500px;
            margin:11px;
            box-sizing: border-box;
            background:linear-gradient(286.94deg, #000000 0.81%, #231E27 99.71%);
            border: 2px solid #000000;
            border-radius: 20px;
            @media (max-width: $tab) {
                width: calc(50% - 22px);
            }
            @media (max-width: $mob) {
                width: calc(100% - 22px);
            }
            .item{
                &__title{
                    position:absolute;
                    top:107px;
                    left:30px;
                    z-index: 1;
                    .title__text{
                        margin-top:15px;
                        font-weight: bold;
                        font-size: 26px;
                        text-shadow: $grey-dark 0px 0px 15px;
                    }
                }
                &__img {
                    position: absolute;
                    bottom:0;
                    right:0;
                }
                &__plus {
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width:60px;
                    height:50px;
                    background:$primary;
                    border-radius : 0 20px;
                    color:$grey-dark;
                    font-weight: bold;
                    font-size: 40px;
                }
                &__link {
                    position: absolute;
                    top:0;
                    left:0;
                    width:100%;
                    height:100%;
                    border-radius: 20px;
                    z-index:1;
                }
            }
        }
    }
}