.home-intro{
    padding:100px 0;
    background:linear-gradient(275.48deg, #000000 7.95%, #3E0A36 99.7%);
    text-align:center;
    .wrap{
        max-width: 1130px;;
    }
    h2{
        font-size:22px;
        font-weight:700;
    }
    p{
        font-size: 17px;
    }
    &__buttons{
        display: flex;
        justify-content: center;
        margin-top:55px;
        @media (max-width: $mob) {
           flex-direction: column;
        }
        .button{
            margin: 5px 10px;
        }
    }
}