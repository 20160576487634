.equipe{
    padding: 50px 0;
    background: linear-gradient(286.94deg, #000000 0.81%, #231E27 99.71%);
    &__list{
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        margin-top:10px;
        .list__item{
            margin:40px 30px 0 30px;
            width:260px;
            border-radius:50px;
            overflow: hidden;
            transform: rotate(-7.24deg);
            background: $grey-dark;
            .item{
                &__img{
                    filter: grayscale(1);
                }
                &__infos{
                    display: flex;
                    justify-content: space-between;
                    padding: 20px 20px 30px 20px;
                    background:$grey-dark;
                    font-weight:600;
                    font-size: 19px;
                    color:$grey-light;
                    span{
                        font-size: 15px;
                        display: block;
                        margin-top: 8px;
                    }
                    .infos__link{
                        color:$primary;
                        font-size:34px;
                    }
                    & > div:first-child{
                        width:calc(100% - 55px);
                    }
                    & > div:last-child {
                        text-align:right;
                        width:40px;
                    }
                }
            }
        }
    }
}