.intro-video{
    position:relative;
    video{
        width:100%;
        display:block;
    }
    &__actions{
        display:flex;
        align-items: center;
        position:absolute;
        bottom:15px;
        right:15px;
        button{
            appearance: none;
            border: 0;
            background: none;
            color: $white;
            font-size: 20px;
            cursor: pointer;
        }
        .actions__pause{
            .fa-play {
                display: inline;
            }
        
            .fa-pause {
                display: none;
            }
            &--paused{
                .fa-play{
                    display:none;
                }
                .fa-pause{
                    display: inline;
                }
            }
        }
        .actions__mute {
            .fa-volume-high {
                display: inline;
            }

            .fa-volume-xmark {
                display: none;
            }

            &--muted {
                .fa-volume-high {
                    display: none;
                }

                .fa-volume-xmark {
                    display: inline;
                }
            }
        }
    }
}