.text-img{
    &--gradient{
        background: linear-gradient(275.48deg, #000000 52.39%, #3E0A36 99.7%);
    }
    .wrap{
        display:flex;
        justify-content: space-between;
        align-items: center;
        @media (max-width: $tab) {
            flex-direction: column;
        }
    }
    &__img{
        width:50%;
        max-width:704px;
        @media (max-width: $tab) {
            width: 100%;
        }
    }
    &__text{
        width: 50%;
        padding:70px 100px 70px 0;
        @media (max-width: $tab) {
            width: 100%;
            padding-right: 0;
            padding-bottom: 35px;
        }
    }
    &--reverse {
        .wrap {
            flex-direction: row-reverse;
            @media (max-width: $tab) {
                flex-direction: column;
            }
        }
        .text-img {
            &__text {
                padding-right: 0;
                padding-left: 100px;
                @media (max-width: $tab) {
                    padding-left: 0;
                }
            }
        }
    }
}
