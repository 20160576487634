.posts-list{
    &__item{
        position:relative;
        height:143px;
        margin-bottom:30px;
        background-repeat: no-repeat;
        background-size: cover;
        border-radius:10px;
        overflow: hidden;
        .item__link{
            position:absolute;
            top:0;
            left:0;
            width:100%;
            height:100%;
            border-radius: 10px;
        }
        .item__text{
            display: flex;
            justify-content: center;
            flex-direction: column;
            position:absolute;
            right:0;
            top:0;
            width:332px;
            height:143px;
            box-sizing: border-box;
            padding:15px 20px 15px 80px;
            background:url(../images/bg-posts.svg) no-repeat ;
            color:$black;
            .text{
                &__categories{
                    display: flex;
                    span{
                        padding:2px 5px 1px 5px;
                        margin-top:5px;
                        margin-right:10px;
                        border:1px solid $black;
                        font-weight: 700;
                        font-size: 9px;
                        border-radius:5px;
                        text-transform: uppercase;
                    }
                }
                &__title{
                    margin-top:10px;
                    font-weight: 700;
                    font-size: 20px;
                }
                &__excerpt {
                    margin:0;
                    font-weight: 500;
                    font-size: 16px;
                }
                &__date {
                    font-weight: 600;
                    font-size: 11.3225px;
                }
                &__place{
                    width: fit-content;
                    margin: 10px 0 -8px 0;
                    padding:2px 15px;;
                    background:#0D4439;
                    font-weight: 500;
                    font-size: 16px;
                    color: $white;
                }

            }
        }

        &--md{
            height: 278px;
            border-radius: 20px 20px 30px 20px;
            @media (max-width: $tab) {
                height: 143px;
                border-radius: 10px;
            }
            .item__text {
                top: auto;
                bottom: 0;
                width: 563px;
                height: 278px;
                background: url(../images/bg-posts-md.svg) no-repeat;

                @media (max-width: $tab) {
                    width: 332px;
                    height: 143px;
                    box-sizing: border-box;
                    padding: 15px 20px 15px 80px;
                    background: url(../images/bg-posts.svg) no-repeat;
                }

                .text {
                    &__categories {
                        span {
                            padding: 2px 8px 0px 8px;
                            margin-top: 5px;
                            margin-right: 15px;
                            font-size: 12px;
                            border-radius: 5px;

                            @media (max-width: $tab) {
                                padding: 2px 5px 1px 5px;
                                margin-top: 5px;
                                margin-right: 10px;
                                font-size: 9px;
                            }
                        }
                    }
                    &__title {
                        margin-top: 20px;
                        font-size: 31px;

                        @media (max-width: $tab) {
                            font-size: 20px;
                        }
                    }

                    &__excerpt {
                        margin-top: 10px;
                        ;
                        font-size: 24px;
                        font-weight: normal;

                        @media (max-width: $tab) {
                            margin: 0;
                            font-weight: 500;
                            font-size: 16px;
                        }
                    }

                    &__date {
                        margin-top:10px;
                        font-size: 14px;

                        @media (max-width: $tab) {
                            font-size: 11.3225px;
                        }
                    }
                    &__link {
                        margin-top: 10px;
                        font-weight: 700;
                        font-size: 14px;
                        color: $black;
                        text-decoration: none;;
                    }
                }
            }
        }

        &--big {
            border-radius: 20px 20px 30px 20px;
            @media (max-width: $tab) {
                height: 143px;
                border-radius: 10px;
            }
            .item__text {
                top:auto;
                bottom: 0;
                width: 489px;
                height: 498px;
                background: url(../images/bg-posts-big.svg) no-repeat;
                @media (max-width: $tab) {
                    width: 332px;
                    height: 143px;
                    box-sizing: border-box;
                    padding: 15px 20px 15px 80px;
                    background: url(../images/bg-posts.svg) no-repeat;
                }
                .text {
                    &__categories {
                        span {
                            padding: 2px 8px 0px 8px;
                            margin-top: 5px;
                            margin-right: 15px;
                            font-size: 12px;
                            border-radius: 5px;
                            @media (max-width: $tab) {
                                padding: 2px 5px 1px 5px;
                                margin-top: 5px;
                                margin-right: 10px;
                                font-size: 9px;
                            }
                        }
                    }
            
                    &__title {
                        margin-top:20px;
                        font-size: 31px;
                        @media (max-width: $tab) {
                            font-size: 20px;
                        }
                    }
            
                    &__excerpt {
                        margin-top: 10px;;
                        font-size: 24px;
                        font-weight: normal;
                        @media (max-width: $tab) {
                            margin: 0;
                            font-weight: 500;
                            font-size: 16px;
                        }
                    }
            
                    &__date {
                        font-size: 14px;
                        @media (max-width: $tab) {
                            font-size: 11.3225px;
                        }
                    }
                }
            }
        }
    } 
}