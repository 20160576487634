.header{
	position:sticky;
	top:0;
	left:0;
	z-index:$z-header;
	width:100%;
	background:$grey-dark;
	transition: all 0.25s;
	&.active{
		box-shadow: 0 4px 21px 4px rgba(0, 0, 0, .1);
	}
	& > .wrap{
		position:relative;
		display: flex;
		align-items: center;
		justify-content: center;
		height:215px;
		transition: all 0.25s;
		@media (max-width: $tab) {
			//height:70px;
		}
	}
	.logo{
		display: flex;
		align-items: center;
		justify-content: center;
		transition: all 0.25s;
		&__img{
			position:relative;
			z-index: 1;
			@media (max-width: $mob) {
				width:200px
			}
		}
	}
	.button-open-menu{
		display:none;
		position: absolute;
		right: 25px;
		top: 80px;
		font-size: 30px;
		border: 0;
		background: none;
		color: $white;
		cursor: pointer;
		transition: 0.2s;;
		@media (max-width: $tab) {
			display: block;
		}
		@media (max-width: $mob) {
			right: 10px;
		}
	}

	.button-close-menu {
		display: none;
		position: absolute;
		right: 15px;
		top: 25px;
		font-size: 30px;
		border: 0;
		background: none;
		color: $white;
		cursor: pointer;
		transition: 0.2s;
		z-index: 12;
		&.active{
			@media (max-width: $tab) {
				display: block;
			}
		}
	}
	.menu-wrap{
		@media (max-width: $tab) {
			position: fixed;
			top:-100%;
			left:0;
			display: block;
			width:100%;
			padding:30px 0;
			background: $grey-dark;
			z-index: 10;
			height: auto;
			text-align: center;
			border-bottom:2px solid $grey-light;
			transition: all 0.2s;
		}
		&.active{
			@media (max-width: $tab) {
				top: 0;
			}
		}
	}
	.menu-container{
		position:absolute;
		left:0;
		top:0;
		display: flex;
		justify-content: center;
		align-items: center;
		width:100%;
		height:100%;
		@media (max-width: $tab) {
			position: relative;
			display:block;
		}
		span{
			@media (max-width: $tab) {
				display: block;
				margin-bottom: 15px;
			}
		}
		a{
			margin:0 25px;
			color:$white;
			text-decoration: none;
			text-transform: uppercase;
			font-size:15px;
			font-weight:500;
			transition: all 0.2s;
			&:hover{
				color:$primary;
			}
			@media (max-width: $tab) {
				font-size:18px;
			}
		}
		.menu{
			display:flex;
			align-items:center;
			padding:0;
			margin:0;
			@media (max-width: $tab) {
				flex-direction: column;
			}
			&-item{
				margin:0;
				list-style:none;
				@media (max-width: $tab) {
					@media (max-width: $tab) {
						margin-bottom: 10px;
					}

				}
				&.current-menu-item{
					a{
						color: $primary;
					}
				}
			}
			&__left {
				margin-right: 150px;
				transition: all 0.2s;
				width:360px;
				@media (max-width: $tab) {
					margin-right: 0;
					width: auto;
				}
				.menu{
					justify-content: flex-end;
				}
			}
		
			&__right {
				margin-left: 150px;
				width: 360px;
				transition: all 0.2s;
				@media (max-width: $tab) {
					margin-left: 0;
					width: auto;
				}
				.menu {
					justify-content: flex-start;
				}
			}
		}
		
	}
	.menu-sup{
		position:absolute;
		top:10px;
		right:15px;
		display: flex;
		align-items: center;
		@media (max-width: $tab) {
			position: relative;
			display: block;
			right: auto;
			top:auto;
		}
		&__lang{
			margin-left:10px;
			@media (max-width: $tab) {
				display:block;
				margin-left : 0;
				margin-top:15px;
			}
			a{
				margin-left: 10px;
				color:$white;
				text-decoration:none;
				&:first-child{
					@media (max-width: $tab) {
						margin-left: 0;
					}
				}
				&.active, &:hover{
					text-decoration: underline;
				}
			}
		}
	}
	&.active{
		& > .wrap {
			height: 100px;
			
			@media (max-width: $tab) {
				//height:70px;
			}
		}
		.logo {
			transform: translateY(40px);
		}
		.button-open-menu{
			top:25px;
		}
		.menu{
			&__left {
				transform: translateY(25px);
				@media (max-width: $tab) {
					transform: translateY(0);
				}
			}
		
			&__right {
				transform: translateY(25px);
				@media (max-width: $tab) {
					transform: translateY(0);
				}
			}
		}
		
	}
}