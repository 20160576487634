.noscroll {
  overflow-y: hidden;
  margin-right:17px;
  @media (max-width: $tab) {
    margin-right:0;
  }
}

.center {
  text-align: center;
}

.italic {
  font-style: italic;
}

.justify-center{
  justify-content: center;
}

.hide-mobile{
  @media (max-width: $mob) {
    display:none !important;
  }
}

.text-primary {
  color: $primary !important;
}



.text-white {
  color: $white !important;
}


.bg-primary {
  background-color: $primary;
}


.bg-white {
  background-color: $white;
}
.bg-black {
  background-color: $black;
}
