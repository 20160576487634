.soutiens{
    padding:110px 0 100px;
    background:black;
    border-top:30px solid $grey-dark;
    &__list{
        display:flex;
        flex-wrap: wrap;
        justify-content: center;
        max-width:1000px;
        margin:70px auto 0;
        .list__item{
            width:210px;
            height:200px;
            margin:15px;
            img{
                display:block;
            }
        }
    }
}