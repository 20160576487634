.select {
    position:relative;
    height: 48px;
    border-radius: 10px;
    background: $white;
    &::before {
        position:absolute;
        top:13px;
        right:15px;
        font-family: "Font Awesome 6 Free";
        font-weight: 900;
        content: "\f107";
        color:$grey;
    }
    select {
        position: absolute;
        appearance: none;
        width: 100%;
        height: 48px;
        box-sizing: border-box;
        padding: 0 40px 0 13px;
        border: 0;
        border-radius: 10px 0 0 10px;
        background:none;
        color: #A3A3A3;
        font-weight: 400;
        font-size: 17px;
        font-family: $font-1;
        outline: none;

    }
}


.select-with-button{
    display:flex;
    max-width:365px;
    height:48px;
    border-radius: 10px;
    background:$white;
    
    .select{
        width:calc(100% - 61px);
    }
    button {
        background:$primary;
        width:61px;
        height:48px;
        border-radius: 0 10px 10px 0;
        border:0;
        appearance: none;
        font-size:17px;
        font-weight:bold;
        cursor:pointer;
        transition: all 0.25s;
        &:hover {
            background-color: $primary-dark;
            border-color: $primary-dark;
        }
    }
}