.page404{
    position: relative;
    
    background: linear-gradient(275.48deg, #000000 57.17%, #3E0A36 99.7%);
    img{
        position:absolute;
        top:0;
        left:0;
        @media (max-width: $tab) {
            width:300px;
        }
    }
    .wrap{
        display:flex;
        justify-content: flex-end;
        align-items: center;
        min-height: 765px;
        &> div{
            width:50%;
            @media (max-width: $tab) {
                padding:350px 0 60px;
                width: 100%;
            }
        }
    }
    &__title{
        color:$primary;
        font-size: 173px;
        font-weight:bold;
        @media (max-width: $tab) {
            font-size: 120px;
        }
    }
    p {
        font-size: 20px;
        &.page404__subtitle {
                margin-top: 30px;
                font-size: 30px;
                font-weight: bold;
            }
    }
    .button{
        margin-top:30px;
    }
    
    
}