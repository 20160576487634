.title{
    font-family: $font-2;
    font-weight: 600;
    &--2{
        font-size: 48px;
        line-height:1.3;
        @media (max-width: $mob) {
            font-size: 40px;
        }
    }
    &--3 {
        font-size: 24px;
    }
    &--4 {
        font-size: 20px;
    }
}