.mentions{
    padding:80px;
    h1{
        margin-bottom:80px;
        color:$white;
        font-size:44px;
        font-weight:bold;
        text-align:center;
    }
    h2{
        margin:45px 0 20px;
        font-family: $font-1;
        font-weight:bold;
    }
}