.block-slider-ligues{
    
    margin-bottom:50px;
    &__title{
        display:flex;
        justify-content: center;
        align-items:center;
        margin:0 -30px;
        padding:3px 30px;
        background:$primary;
        text-align:center;
        color:$black;
        font-weight:bold;
        font-size:15px;
        span{
            margin: 0 15px;
        }
    }
    &__list{
        margin-top:30px;
        .wrap{
            position: relative;
        }
        .swiper-slide{
            display: flex;
            justify-content: center;
        }
        .list__item{
            align-self: center;
            position:relative;
            display:flex;
            
            width:268px;
            height:125px;
            box-sizing: border-box;
            padding:15px;
            border-radius: 20px;
            background-color: #2E2932;
            .item{
                &__img{
                    width:62px;
                    margin-right:15px;
                }
                &__text{
                    .text{
                        &__name{
                            font-weight: 700;
                            font-size: 17px;
                            color:$primary;
                            br{
                                display:none;
                            }
                        }
                        &__infos{
                            .infos{
                                &__season{
                                    display: inline-flex;
                                    align-items: center;
                                    height:16px;
                                    margin-right:6px;
                                    margin-top:10px;
                                    padding:0 10px;
                                    border:1px solid $white;
                                    border-radius:16px;
                                    font-size:9px;
                                    text-transform:uppercase;
                                }
                                &__status {
                                    display: inline-flex;
                                    align-items: center;
                                    height: 16px;
                                    margin-top: 10px;
                                    padding: 0 10px;
                                    border: 1px solid $white;
                                    border-radius: 5px;
                                    font-size: 9px;
                                    text-transform: uppercase;
                                    &--closed{
                                        color:$red;
                                        border-color:$red;
                                    }
                                    &--current {
                                        color: $orange;
                                        border-color: $orange;
                                    }
                                }
                            }
                        }
                        &__numbers {
                            position: relative;
                            margin-top:5px;
                            padding-left: 23px;
                            font-size:12px;
                            font-weight: bold;;
                            img {
                                position: absolute;
                                top: 0;
                                left: 0;

                            }
                        }
                    }
                }
                &__plus{
                    position: absolute;
                    bottom: 0;
                    right: 0;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 32px;
                    height: 30px;
                    background: #a1c514;
                    border-radius: 15px 0 20px 0;
                    color: #231e27;
                    font-weight: 700;
                    font-size: 15px;
                }
                &__link{
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    border-radius: 20px;
                }
            }
        }
    }
    .swiper-ligue-prev, .swiper-ligue-next{
        position:absolute;
        display: flex;
        justify-content: center;
        align-items: center;
        top: calc(50% - 15px);
        width:30px;
        height:30px;
        color: $white;
        outline: none;
        font-size:20px;;
        cursor:pointer;
        z-index: 1;
    }
    .swiper-ligue-prev {
        left: 0;
    }
    .swiper-ligue-next {
        right: 0;
    }
    
   
}
