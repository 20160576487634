.footer{
	padding-top:50px;
	background-color:$grey-dark;
	color:$white;
	&__eco{
		padding:10px 0;
		width:100%;
		background-color: darken($grey-dark, 2%);
		
		img{
			margin-right:10px;
		}
		.wrap{
			display: flex;
			justify-content: center;
			align-items: center;
		}
	}
	&__main{
		display:flex;
		justify-content: space-between;
		@media (max-width: $tab) {
			flex-direction: column;
		}
		h3{
			margin-bottom:15px;
			font-weight: 700;
			font-size: 14px;
			text-transform:uppercase
		}
		.main__left{
			text-align:center;
			a{
				display: inline-block;
				margin:8px 3px 0;
				color:$white;
				font-size:28px;
			}
		}
		.main__center {
			text-align:center;
			@media (max-width: $tab) {
				text-align: center;
				width: 100%;
				margin-top: 50px;
			}
			.center__content{
				display: flex;
				flex-wrap: wrap;
				justify-content: center;
				align-items: center;
			}
		}
		.main__right {
			width:205px;
			font-size:14px;
			@media (max-width: $tab) {
				text-align:center;
				width:100%;
				margin-top:50px;
			}
			ul{
				padding:0;
				list-style: none;
				margin:0;
				li{
					margin-bottom:3px;
				}
			}
			a{
				color:rgba($white, 0.8);
				text-decoration:none;
				&:hover{
					text-decoration: underline;
				}
			}
		}
	}
	&__leagues{
		margin-top:30px;
		text-align:center;
		color: rgba($white, 0.8);
		font-size:14px;
		a {
			color: rgba($white, 0.8);
			text-decoration: none;
	
			&:hover {
				text-decoration: underline;
			}
		}

	}

	&__mentions{
		margin-top:70px;
		padding:15px 0;
		border-top: 1px solid rgba($white, 0.2);
		color: rgba($white, 0.8);
		font-size: 14px;
		text-align:center;
		a {
			color: rgba($white, 0.8);
			text-decoration: none;
	
			&:hover {
				text-decoration: underline;
			}
		}
	}
	
	
	
}