.home-news{
    background:$black;
    &__content{
        display:flex;
        justify-content: space-between;
        padding-top:70px;
        @media (max-width: $tab) {
            flex-direction: column;
        }
        .content{
            &__left{
                width:56%;
                @media (max-width: $tab) {
                    width: 100%;
                }
                .first-news{
                    height: 100%;
                    @media (max-width: $tab) {
                        height:143px;
                    }
                    
                }
            }
            &__right{
                width: calc(44% - 30px);
                @media (max-width: $tab) {
                        width: 100%;
                    }
                img{
                    display: block;
                }
                .right__link{
                    font-weight: bold;
                    font-size: 15px;
                    color:$primary;
                    text-transform: uppercase;
                }
            }
        }
    }
}