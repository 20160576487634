.home-comment-participer{
    position:relative;
    padding:80px 0 120px 0;
    background: linear-gradient(275.48deg, #000000 52.39%, #3E0A36 99.7%);
    &__content{
        max-width:530px;
        @media (max-width: $mob) {
            max-width: 100%;
        }
        h2{
            margin-bottom:30px;
        }
        p{

        }
    }
    &__img{
        position:absolute;
        top:0;
        right:0;   
        height:100% !important; 
        max-width:50%;
        object-fit: cover;
        object-position: left center;
        @media (max-width: $tab) {
           display:none;
        }
    }
}