.pagination {
  margin-bottom: 25px;
  text-align: center;
  .page-numbers {
    display: inline-flex;
    justify-content: center;
    width: 25px;
    height: 25px;
    border-radius: 15px;
    align-items: center;
    text-decoration: none;
    background: $black;
    color: $primary;
    text-decoration: none;
    transition:all 0.25s;
    &:hover, &.current {
      background:$primary;
      color:$black;
    }
    &.next, &.prev{
      display:none;
    }
  }
}
