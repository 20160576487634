.news-single{
    background: linear-gradient(286.94deg, #000000 0.81%, #231E27 99.71%);
    &__title{
        background:black;
        text-align:center;
        padding:40px 0;
        border-top:3px solid $primary;
        border-bottom:1px solid $white;
        h1{
            font-weight: 700;
            font-size: 32px;
            color:$white;
            margin:0;
            opacity:0.5;
            text-transform:uppercase;
        }
    }
    &__content{
        display:flex;
        justify-content: space-between;
        padding:40px 0;
        @media (max-width: $mob) {
           display:block;
        }
        .content{
            &__left{
                width:calc(100% - 380px);
                @media (max-width: $mob) {
                    width: 100%;
                }
                p:first-child{
                    margin-top:0;
                }
                h2:first-child {
                    margin-top: 0;
                }
                h2{
                    font-size:20px;
                    font-weight:bold;
                }
                img, video{
                    max-width:100%;
                }
                a{
                    color:$primary;
                }
            }
            &__right {
                width:300px;
                @media (max-width: $mob) {
                    width: 100% ;
                    margin-top:50px;
                }
                .right{
                    position: sticky;
                    top: 120px;
                    &__section{
                        padding: 15px 0;
                        border-top: 1px solid rgba($white, 0.3);
                        font-weight: 500;
                        font-size: 15px;
                        color:$white;
                        a {
                            color: $white;
                        }
                    }
                }
            }
        }
    }
}

div.heateor_sss_follow_ul a,
div.heateor_sss_horizontal_sharing div.heateor_sss_sharing_ul a{
    margin: 10px 7px 0 0 !important;
}