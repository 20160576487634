$white: #ffffff;
$black: #000000;

$text-color: #0F030D;

$grey-light: #D8D4E1;
$grey: #6B6B6B;
$grey-dark: #231E27;

$primary: #A1C514;
$primary-dark: #799310;

$red : #EB573F;
$orange : #F6A307;