.home-slider-testimonials{
    position:relative;
    padding:80px 0 120px 0;
    background:$black;
    color:$black;
    &__list{
        margin-top:60px;
        .wrap{
            position: relative;
            padding: 0 50px;
            @media (max-width: $mob) {
               padding: 0 35px;
            }
        }
        .swiper-testimonials{
            
            background: $grey-light;
            border-radius: 30px;
        }
        .list__item{
            display:flex;
            justify-content: space-between;
            align-items:center;
            padding: 40px 70px 50px;
            @media (max-width: $mob) {
                display:block;
                padding: 40px 20px 20px;
            }
            .item{
                &__img{
                    width:270px;
                    transform: rotate(-7.24deg);
                    @media (max-width: $mob) {
                        display: block;
                        margin:auto;
                    }
                    &::before{
                        position:absolute;
                        top:11px;
                        left:11px;
                        content:'';
                        width:100%;
                        height:100%;
                        background-color:$primary;
                        border-radius: 30px;
                    }
                    img{
                        position:relative;
                        z-index: 1;
                        display:block;
                        border-radius: 30px;
                        width:270px;
                        height:270px;
                        object-fit: cover;
                    }
                }
                &__text{
                    width:calc(100% - 370px);
                    @media (max-width: $mob) {
                        width:100%;
                        margin-top:50px;
                    }
                    p{
                        font-size:16px;
                        &.text__person{
                            font-size:17px;
                            font-weight:bold;
                        }
                    }
                }
            }
        }
    }
    .swiper-button-next, .swiper-button-prev{
        color:$primary;
        outline:none;
    }
    .swiper-button-prev {
        left: 5px;
    }
    .swiper-button-next{
        right:5px;
    }
}