.page-post-list{
    .wrap{
        display:flex;
        justify-content: space-between;
        @media (max-width: $mob) {
            display:block;
        }
    }

    .searchandfilter{
        ul{
            padding:0;
            li{
                width: 100%;
                margin-bottom: 0;
                h4{
                    margin-top: 20px;
                    margin-bottom:0;
                    padding: 7px 22px;
                    font-weight: bold;
                    font-size: 17px;
                    border-bottom: 1px solid $white;
                    opacity: 0.5;
                }
                ul{
                    padding: 0 22px;
                    margin-top: 15px;
                    @media (max-width: $mob) {
                        display: flex;
                        flex-wrap: wrap;
                    }
                    li {
                        display: flex;
                        align-items: center;
                        margin-top:0;
                        margin-bottom: 5px;

                        @media (max-width: $mob) {
                            margin-right: 25px;
                            width:auto;
                        }

                        input {
                            margin-right: 10px;
                            ;
                        }
                    }
                }
            }
        }
        input[type="submit"]{
            display: inline-flex;
            align-items: center;
            justify-content: center;
            margin:15px 0 0 22px;
            padding: 5px 16px;
            font-size: 16px;
            background-color: $primary;
            border: 3px solid $primary;
            border-radius: 10px;
            color: $text-color;
            font-weight: bold;
            text-decoration: none;
            cursor: pointer;
            transition: all 0.25s;
        
            &:hover {
                background-color: $primary-dark;
                border-color: $primary-dark;
            }
        }
    }
    &__filters{
        width:310px;
        margin-bottom:40px;
        @media (max-width: $mob) {
            width:100%;
        }
        .filters{
            &__title{
                display:flex;
                align-items: center;
                padding:0 22px;
                height:45px;
                background:#29262A;
                font-weight: bold;
                font-size: 15px;
                text-transform:uppercase;
            }
            &__subtitle{
                margin-top:20px;
                padding: 7px 22px;
                font-weight: bold;
                font-size: 17px;
                border-bottom:1px solid $white;
                opacity: 0.5;
            }
            &__content{
                padding: 0 22px;
                margin-top:15px;
                @media (max-width: $mob) {
                    display:flex;
                    flex-wrap:wrap;
                }
                .content__line{
                    display:flex;
                    align-items: center;
                    margin-bottom:5px;
                    @media (max-width: $mob) {
                        margin-right:25px;
                    }
                    input{
                        margin-right:10px;;
                    }
                }
            }
        }
    }
    &__content{
        width: calc(100% - 360px);
        @media (max-width: $mob) {
                width: 100%;
            }
    }
}