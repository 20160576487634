.ligues{
    background: linear-gradient(286.94deg, #000000 0.81%, #231E27 99.71%);
    background: $black;
    .wrap{
        display: flex;
        justify-content: space-between;

    }
    &__left{
        width:calc(50% - 17px);
        @media (max-width: $tab) {
            width:100%;
        }
        .left__top{
            display: flex;
            align-items:center;
            height:80px;
            border-right:1px solid rgba($white, 0.3);
            border-bottom: 1px solid rgba($white, 0.3);
            @media (max-width: $tab) {
               border-right:0;
            }
            h1{
                opacity: 0.5;
                text-transform:uppercase;
                font-family:$font-2;
                font-weight: 600;
                font-size: 20px;
            }
        }

    }
    
    &__right{
        width: calc(50% - 17px);
        @media (max-width: $tab) {
            display:none;
        }
        &.default{
            padding-top: 150px;
            font-size: 32px;
            font-weight: bold;
            text-align: center;
        }
        
    }
    &__list{
        position: sticky;
        top: 100px;
        .list__item{
            border-bottom: 1px solid rgba($white, 0.3);
            .item {
                &__title {
                    position: relative;
                    display: flex;
                    align-items: center;
                    height: 160px;
                    border-right: 5px solid transparent;
                    transition: all 0.2s;
                    padding-right:65px;
                    @media (max-width: $tab) {
                        height: 120px;
                    }
                    &.active {
                        border-color: $primary;
                        .title {
                            &__img {
                                img{
                                    filter: grayscale(0);
                                }
                            }
                            &__text {
                                opacity: 1;
                            }
                            &__expand {
                                opacity: 1;
                            }
                           
                        }
                    }
        
                    .title {
                        &__img {
                            width:153px;
                            img{
                                height: 82px !important;
                                width: auto;
                                filter: grayscale(1);
                            
                                @media (max-width: $tab) {
                                    height: 50px !important;
                                }
                            }
                            
                        }
        
                        &__text {
                            width:calc(100% - 153px);
                            font-family: $font-2;
                            font-weight: 700;
                            font-size: 35px;
                            text-transform: uppercase;
                            opacity: 0.3;
                            @media (max-width: $tab) {
                               // height: 27px !important;
                            }
                        }
        
                        &__expand {
                            position: absolute;
                            right: 10px;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            width: 50px;
                            height: 50px;
                            padding: 0;
                            font-size: 50px;
                            border: 0;
                            background: none;
                            color: $white;
                            opacity: 0.3;
                            cursor: pointer;
                            transition: all 0.2s;
        
                            .expand__minus {
                                display: none;
                            }
        
                            &:hover {
                                color: $primary;
                                opacity: 1;
                            }
                        }
                    }
        
                    &.active {
                        .title {
                            &__expand {
        
                                .expand__plus {
                                    display: none;
                                }
        
                                .expand__minus {
                                    display: block;
                                }
                            }
                        }
                    }
                }
        
                &__details {
                    display:none;
                    padding: 20px 35px 10px 35px;
                    background: $grey-dark;
                    @media (max-width: $tab) {
                        margin: 0 -15px;
                        padding: 1px 15px 10px 15px;
                    }
                    .details {
                        &__title {
                            margin: 30px 0 15px 0;
                            font-weight: bold;
                            font-size: 19px;
                            @media (max-width: $tab) {
                                font-size: 18px;
                            }
                        }
                    }
                }
            }
            .classement{
                display:none;
                @media (max-width: $tab) {
                    display: block;
                }
            }
        }
        
    }

    .classement {
        position: sticky;
        top: 100px;
        @media (max-width: $tab) {
            position: relative;
            top:auto;
            margin-top:20px;
        }
        &__header {
            background: $black;
            @media (max-width: $tab) {
                background: $grey-dark;
            }
            .header {
                &__top {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    height: 80px;
                    border-bottom: 1px solid rgba($white, 0.3);
                    @media (max-width: $tab) {
                        display: block;
                        padding-bottom:5px;
                    }
                    & > div {
                        display: flex;
                        align-items: center;
                        @media (max-width: $tab) {
                            justify-content: space-between;
                            margin-bottom:10px;
                        }
                        h2 {
                            opacity: 0.5;
                            text-transform: uppercase;
                            font-family: $font-2;
                            font-weight: 600;
                            font-size: 20px;
                            @media (max-width: $tab) {
                                font-size: 18px;
                            }
                        }

                        .top__season {
                            margin-left: 20px;
                            padding: 3px 10px;
                            font-weight: 600;
                            font-size: 16px;
                            opacity: 0.5;
                            text-transform: uppercase;
                            font-family: $font-2;
                            border: 1px solid $white;
                            border-radius: 10px;
                        }
                    }
                }

                &__bottom {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    padding-left: 25px;
                    border-bottom: 1px solid rgba($white, 0.3);
                    @media (max-width: $tab) {
                        padding-left:0;
                        justify-content: flex-start;
                    }
                    &>div {
                        padding: 5px 0;
                        font-weight: 700;
                        font-size: 14px;
                        color: $white;
                        opacity: 0.6;
                        text-transform: uppercase;
                        @media (max-width: $tab) {
                            font-size: 13px;
                        }
                        &:first-child {
                            width: 80px;
                            @media (max-width: $tab) {
                                width: 75px;
                            }
                        }

                        &:nth-child(2) {
                            width: 100px;
                            text-align: center;
                            @media (max-width: $tab) { 
                                width: 75px;
                            }
                        }

                        &:nth-child(3) {
                            width: 180px;
                            text-align: center;
                            @media (max-width: $tab) {
                                width: calc(100% - 240px);
                            }
                        }

                        &:nth-child(4) {
                            width: 120px;
                            text-align: center;
                            @media (max-width: $tab) {
                                width: 100px;
                            }
                        }
                    }
                }
            }
        }

        &__content {
            height: calc(100vh - 228px);
            overflow-y: auto;
            @media (max-width: $tab) {
                background:$black;;
                height:auto;
                overflow-y:visible;
            }
            .content__debut{
                padding: 20px 0;
                text-align: center;
                font-size: 30px;
                font-weight: bold; 
                .debut__link{
                    margin-top:20px;
                }
            }
            .content__item {
                margin-top: 20px;
                @media (max-width: $tab) {
                    margin: 0;
                }

                &:first-child {
                    margin: 0;
                }

                .item {
                    &__title {
                        height:auto;
                        padding: 10px 25px;
                        background: $grey-dark;
                        font-weight: bold;
                        font-size: 14px;
                        color: $white;
                        text-transform: uppercase;
                        @media (max-width: $tab) {
                            padding: 10px 10px 10px 0;
                        }
                    }

                    &__rank {
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        height: 105px;
                        padding-left: 25px;
                        border-bottom: 1px solid rgba($white, 0.3);
                        @media (max-width: $tab) {
                            justify-content: flex-start;
                            padding-left:10px;
                            flex-wrap:wrap;
                        }
                        &:last-child {
                            border: 0;
                        }

                        .rank {
                            &__1 {
                                display: flex;
                                align-items: center;
                                width: 80px;
                                @media (max-width: $tab) {
                                    width: 65px;
                                }
                                span {
                                    width: 30px;
                                    font-weight: bold;
                                    font-size: 30px;
                                    @media (max-width: $tab) {
                                        width: 22px;
                                        font-size: 22px;
                                    }
                                }
                                img{
                                    @media (max-width: $tab) {
                                        width: 25px;
                                    }
                                }
                            }

                            &__2 {
                                width: 100px;
                                text-align: center;
                                @media (max-width: $tab) {
                                    width: 75px;
                                }
                                img{
                                    width: 100%;
                                    height: 60px !important;
                                    object-fit: contain;
                                    display: block;
                                }
                            }

                            &__3 {
                                width: 180px;
                                text-align: center;
                                font-weight: 500;
                                font-size: 17px;
                                text-transform: uppercase;
                                @media (max-width: $tab) {
                                    font-size: 15px;
                                    width: calc(100% - 240px);
                                }
                            }

                            &__4 {
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                width: 120px;
                                text-align: center;
                                font-weight: 500;
                                font-size: 27px;
                                @media (max-width: $tab) {
                                    width:100px;
                                    font-size: 21px;
                                }
                                img {
                                    margin-right: 10px;
                                }
                                                               
                            }
                        }
                    }
                }
            }
        }
    }
}
